<template>
	<div>
		<CustomTable
			:recent-filter="recentFilters"
			:total-pages="totalPages"
			:total-items="totalItems"
			:label-search="$t('TypesFilter.search')"
			:label-date="$t('TypesFilter.createdDate')"
			:show-create-button="true"
			:text-create-button="$t('TypeButtons.create')"
			@create="create"
			@search="search"
		>
			<template #headers>
				<tr>
					<th
						v-for="(header, index1) in headers"
						:key="`${header.title}-${index1}`"
						scope="col"
						:class="`${header.class}`"
					>
						{{ header.title }}
					</th>
				</tr>
			</template>
			<template #body>
				<template v-if="typesOrder && typesOrder.length">
					<tr
						v-for="(type, index2) in typesOrder"
						:key="index2"
						:class="{ 'highlight-row': type.id === idHighlightRow }"
					>
						<td scope="row" class="text-break text-pre-wrap text-center">
							{{ type.type }}
						</td>
						<td scope="row" class="text-break text-pre-wrap text-center fit">
							{{ type.id }}
						</td>
						<td scope="row" class="text-break text-pre-wrap text-center">
							{{ convertDateToDateVN(type.createdAt) }}
						</td>
						<td scope="row" class="fit text-center actions">
							<router-link
								:to="{
									name: 'TypeOrderDetail',
									params: { id: type.id },
								}"
								class="link-icon"
							>
								<span class="prefix-input d-inline-block mr-3">
									<font-awesome-icon :icon="['fas', 'pencil-alt']" :title="$t('Action.Edit')" />
								</span>
							</router-link>
							<span class="prefix-input d-inline-block trash" @click="showConfirmDelete(type.id)">
								<font-awesome-icon :icon="['fas', 'trash-alt']" :title="$t('Action.Delete')" />
							</span>
						</td>
					</tr>
				</template>
				<template v-else>
					<tr class="text-center">
						<td colspan="11" class="fit">
							{{ $t("Table.NoData") }}
						</td>
					</tr>
				</template>
			</template>
		</CustomTable>
		<CustomModal
			v-if="isShowConfirmDelete"
			:title="$t('messages.delete.header')"
			:submit-text="$t('TypeButtons.delete')"
			@submit-modal="confirmDelete"
		>
			<template #content>
				{{ $t("messages.delete.body") }}
			</template>
		</CustomModal>
	</div>
</template>

<script>
import { GET_TYPES_ORDER, DELETE_TYPE_ORDER } from "../store/action-types"
import { RESET_STATE, SET } from "../store/mutation-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions, mapState, mapMutations } = createNamespacedHelpers("typeOrder")

export default {
	name: "TypeOrderManagement",
	data() {
		return {
			idDelete: null,
			isShowConfirmDelete: false,
			headers: [
				{
					title: this.$t("TypeFieldTable.type"),
					class: "text-nowrap text-center",
				},
				{
					title: this.$t("TypeFieldTable.code"),
					class: "text-nowrap title text-center fit",
				},
				{
					title: this.$t("TypeFieldTable.createdDate"),
					class: "text-nowrap text-center",
				},
				{
					title: this.$t("TypeFieldTable.actions"),
					class: "fit text-center",
				},
			],
		}
	},
	computed: {
		...mapState([
			"typesOrder",
			"totalItems",
			"totalPages",
			"recentFilters",
			"idHighlightRow",
			"modalDelete",
		]),
	},
	created() {},
	beforeDestroy() {
		if (!this.$route.path.includes("typeOrder")) this.RESET_STATE()
	},
	methods: {
		...mapActions({
			GET_TYPES_ORDER,
			DELETE_TYPE_ORDER,
		}),
		...mapMutations({ RESET_STATE, SET }),
		create() {
			this.$router.push({ name: "CreateType" })
		},
		search: async function (params) {
			params = {
				data: {
					...params,
				},
				notLoading: false,
			}
			await this.GET_TYPES_ORDER(params)
		},
		showConfirmDelete(id) {
			this.idDelete = id
			this.isShowConfirmDelete = true
			this.$store.commit("set", ["modalShow", true])
		},
		async confirmDelete() {
			const params = {
				id: this.idDelete,
			}
			await this.DELETE_TYPE_ORDER(params).then(res => {
				if (res.ok) {
					this.idDelete = null
					this.isShowConfirmDelete = false
					this.$store.commit("set", ["modalShow", false])
				}
			})
		},
	},
}
</script>

<style></style>
